<template>
  <div class="wrapper">
    <div class="container">
      <p class="title">课题案例</p>
    </div>
    <div class="example-wrapper">
      <div class="example-list">
        <div class="example-item">
          <div
            :class="`example-title ${activeTab == 1 ? 'active' : ''}`"
            @click="switchClass(1)"
          >
            一般课题
          </div>
          <div
            :class="`example-title ${activeTab == 2 ? 'active' : ''}`"
            @click="switchClass(2)"
          >
            重点课题
          </div>
        </div>
        <div class="example-content">
          <img class="example-img" :src="activeTab == 1 ? exampleSrc : exampleSrc2" />
          <div class="example-detail">
            <div class="content">
              <div>
                {{ activeTab == 1 ? detail1 : detail2 }}
              </div>
              <div class="more"><img :src="rightIcon" />了解详情</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      exampleSrc: require('@/assets/class/example1.jpg'),
      exampleSrc2: require('@/assets/class/example2.jpg'),
      rightIcon: require('@/assets/class/example-right.svg'),
      activeTab: 1,
      detail1:
        'AIGC入门与实践-吉利高校训练营第五期,了解AIGC文生图的发展,学习如何使用PAI-ArtLab一站式AIGC设计工具进行创作。',
      detail2:
        '工业元宇宙数字底座体系化关键技术的应用，本课题介绍及应用了数字孪生技术，数字孪生技术在工业元宇宙中应用于产品设计、生产优化、设备维护、员工培训、供应链管理等多个方面，通过创建物理实体的虚拟副本，实现实时监控、预测性维护、流程模拟和优化，从而提高生产效率、降低成本，并加速产品从设计到市场的流程。',
    }
  },
  watch: {},

  filters: {},
  methods: {
    switchClass (value) {
      this.activeTab = value
    },
  },
}
</script>
<style lang="less" scoped>
@import "~@/style/global";

.wrapper {
  .g_center_wrapper();
  background: #fff;
  overflow: hidden;
}

.container {
  .g_center();
}

.title {
  margin-top: 100px;
  margin-bottom: 50px;
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 36px;
  color: #2c3844;
  text-align: center;
}

.description {
  position: relative;
  z-index: 1;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 40px;
  color: #5b5b5b;
}

.swiper_wrapper {
  position: relative;
  width: @g_width;
  min-height: 656px;
  margin: 67px auto 0 auto;
  background: #fff;
  box-shadow: 0px 9px 50px rgba(0, 0, 0, 0.05);
}

.bg_1,
.bg_2 {
  position: absolute;
  background-repeat: no-repeat;
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-pagination {
  position: relative;
  margin: 48px auto 0 auto;
}

/deep/ .swiper-pagination-bullet-home-serve {
  width: 56px;
  height: 12px;
  margin-right: 9px;
  border-radius: 0;
  background: #c4c4c4;
  opacity: 1;

  &:hover,
  &.swiper-pagination-bullet-active {
    opacity: 1;
    background: #007aff;
  }
}
.example-wrapper {
  width: 1684px;
  margin: 0 auto;
  margin-bottom: 100px;
}
.example-item {
  display: flex;
  width: 100%;
  .example-title {
    box-sizing: border-box;
    width: 50%;
    text-align: center;
    height: 118px;
    line-height: 118px;
    font-size: 26px;
    background: rgba(217, 217, 217, 0.2);
    color: rgba(161, 161, 161, 1);
    cursor: pointer;
  }
  .active {
    background: #f8f8f8;
    color: rgba(45, 45, 45, 1);
    border-top: 10px solid #0057ff;
  }
}
.example-detail {
  background: linear-gradient(98.4deg, #3e6fe4 2.09%, #154ad4 100.5%);
  width: 842px;
  justify-content: center;
  display: flex;
  .content {
    justify-content: space-around;
    display: flex;
    flex-direction: column;
    width: 775px;
    color: #fff;
    font-size: 20px;
    font-weight: 400;
    line-height: 28px;
  }
}
.example-content {
  display: flex;
  .example-img{
    width: 842px;
    height: 450px;
  }
}
.more {
  font-family: PingFang SC;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  text-align: right;
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  cursor: pointer;
  img {
    margin-left: 10px;
  }
}
</style>
