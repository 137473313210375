<template>
  <div
    class="wrapper"
    :style="{ backgroundImage: `url(${wrapperText.imgSrc})` }"
  >
    <div class="content">
      <div class="container">
      <p class="description">新能源汽车智能制造</p>
      <p class="subtitle">国家地方联合工程研究中心</p>
      <div class="title">开放课题<img :src="unionPng" /></div>
      <p class="detail">
        为推动新能源汽车智能制造领域的基础研究和技术创新、促进学术交流与成果产出、发现和培养本领域科技人才，新能源汽车智能制造国家地方联合工程研究中心设立开放课题，资助国内外科技工作者开展研究工作
      </p>
    </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Banner',
  props: {
    wrapperText: {
      type: Object,
      default: () => {},
    },
  },
  data () {
    return {
      unionPng: require('@/assets/class/union.svg'),
    }
  },
}
</script>
<style lang="less" scoped>
@import "~@/style/global";

.wrapper {
  .g_center_wrapper();
  height: 544px;
  background-size: 1920px 524px, 100% 100%;
  background-position: 57% top;
  background-repeat: no-repeat;
  background-color: rgba(255, 255, 255, 0.1);
}
.content{
  width: 1294px;
  height: 544px;
  background: linear-gradient(82.18deg, rgba(255, 255, 255, 0.7) 32.38%, rgba(196, 196, 196, 0) 85.94%);

}
.container {
  // .g_center();
  margin-left: 250px;
  height: 100%;
  overflow: hidden;

}

.title {
  font-family: PingFang SC;
  font-size: 54px;
  font-weight: 600;
  line-height: 52px;
  letter-spacing: 0.01em;
  text-align: left;
  color: rgba(255, 255, 255, 0.3);
  // text-shadow: 0 0 0 #000;
  -webkit-text-stroke: 1px #000;
  img{
    margin-left: 20px;
  }
}

.description {
  width: 690px;
  margin: 130px 0 0 0;
  font-family: PingFang SC;
  font-size: 20px;
  font-weight: 400;
  text-align: left;
}
.subtitle {
  font-family: PingFang SC;
  font-size: 40px;
  font-weight: 600;
  line-height: 70px;
  letter-spacing: 0.01em;
  text-align: left;
  color: #000;
text-shadow: 3px 3px #fff;

}
.detail {
  width: 1005px;
  height: 100px;
  font-family: PingFang SC;
  font-size: 20px;
  font-weight: 300;
  line-height: 38px;
  text-align: left;

  margin-top: 50px;
}
</style>
