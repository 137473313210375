var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wrapper"},[_vm._m(0),_c('div',{staticClass:"result-wrapper"},[_c('div',{staticClass:"result-list"},[_c('div',{staticClass:"result-item"},[_c('div',{staticClass:"top"},[_vm._m(1),_c('img',{staticClass:"right",attrs:{"src":_vm.imgs[0],"alt":""}})]),_vm._m(2)]),_c('div',{staticClass:"result-item"},[_c('div',{staticClass:"top"},[_vm._m(3),_c('img',{staticClass:"right",attrs:{"src":_vm.imgs[1],"alt":""}})]),_vm._m(4)]),_c('div',{staticClass:"result-item"},[_c('div',{staticClass:"top"},[_vm._m(5),_c('img',{staticClass:"right",attrs:{"src":_vm.imgs[0],"alt":""}})]),_vm._m(6)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_c('p',{staticClass:"title"},[_vm._v("本期课题揭榜")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"left"},[_c('p',{staticClass:"name"},[_vm._v("张桦")]),_c('p',{staticClass:"school"},[_vm._v("华中科技大学")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bottom"},[_c('div',{staticClass:"result-title"},[_vm._v("工业元宇宙数字底座体系化关键技术")]),_c('div',{staticClass:"result-content"},[_c('div',{staticClass:"left"},[_c('p',{staticClass:"date-title"},[_vm._v("课题揭榜时间")]),_c('p',{staticClass:"date"},[_vm._v("2024-05-29")])]),_c('div',{staticClass:"right"},[_vm._v("一般课题")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"left"},[_c('p',{staticClass:"name"},[_vm._v("沈煜弘")]),_c('p',{staticClass:"school"},[_vm._v("新加坡国立大学")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bottom"},[_c('div',{staticClass:"result-title"},[_vm._v("基于扩散模型和对比学习的表面缺陷视觉检测技术研究")]),_c('div',{staticClass:"result-content"},[_c('div',{staticClass:"left"},[_c('p',{staticClass:"date-title"},[_vm._v("课题揭榜时间")]),_c('p',{staticClass:"date"},[_vm._v("2024-06-25")])]),_c('div',{staticClass:"right"},[_vm._v("一般课题")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"left"},[_c('p',{staticClass:"name"},[_vm._v("王心蕊")]),_c('p',{staticClass:"school"},[_vm._v("德国亚琛工业大学")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bottom"},[_c('div',{staticClass:"result-title"},[_vm._v("人工智能技术在工业视觉检测技术中带来的新机遇")]),_c('div',{staticClass:"result-content"},[_c('div',{staticClass:"left"},[_c('p',{staticClass:"date-title"},[_vm._v("课题揭榜时间")]),_c('p',{staticClass:"date"},[_vm._v("2024-07-20")])]),_c('div',{staticClass:"right"},[_vm._v("一般课题")])])])
}]

export { render, staticRenderFns }