<template>
  <div class="wrapper">
    <div class="container">
      <p class="title">申请形式</p>
      <div class="apply-wrapper">
        <div class="apply-item">
          <img :src="imgs[0]" alt="" class="img_wrapper" />
          <p class="subtitle">中心开放</p>
          <p class="description">由工程研究中心开放的预研性科研项目</p>
        </div>
        <div class="apply-item">
          <img :src="imgs[1]" alt="" class="img_wrapper" />
          <p class="subtitle">自带经费</p>
          <p class="description">
            自带经费但利用工程研究中心的仪器设备等科研条件的项目
          </p>
        </div>
        <div class="apply-item">
          <img :src="imgs[2]" alt="" class="img_wrapper" />
          <p class="subtitle">联合申报</p>
          <p class="description">
            联合申报浙江省和宁波市工信、科技部门相关的科研课题
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      imgs: [
        require('@/assets/class/apply1.svg'),
        require('@/assets/class/apply2.svg'),
        require('@/assets/class/apply3.svg'),
      ],
    }
  },
}
</script>
<style lang="less" scoped>
@import "~@/style/global";

.wrapper {
  .g_center_wrapper();
}

.container {
  .g_center();
  height: 100%;
  margin: 62px auto 75px auto;
  overflow: hidden;
}

.title {
  margin: 0 0 24px 0;
  font-family: PingFang SC;
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 45px;
  text-align: center;
  color: #000000;
}
.subtitle {
  font-size: 24px;
  text-align: center;
  font-weight: 600px;
  line-height: 60px;
}
.description {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;
  text-align: center;
  text-indent: 2em;
  text-transform: uppercase;
  color: #3e3e3e;
}

.apply-wrapper {
  display: flex;
  align-items: center;
  width: 1350px;
  height: 280px;
  justify-content: space-between;
}
.apply-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 340px;
  height: 270px;
  justify-content: space-around;
}
</style>
