<template>
  <div class="wrapper">
    <div class="container">
      <p class="title">常见问题</p>
      <div class="question-wrapper">
        <div class="question-list">
          <div class="question-item">
            <div class="question-title">Q：课题成果所有权怎么界定？</div>
            <div class="answer">
              <p>
                （一）受工程研究中心基金资助课题所取得的成果（包括发表的论文、论著，授权的专利，所获奖励以及收集到的资料、研究报告、相应软件及其测试检验报告等）归研究者与工程研究中心共同所有，成果署名单位须有工程研究中心，具体排序可协商。
              </p>
              <p>
                （二）自带经费但利用工程研究中心的仪器设备等科研条件所取得的成果归研究者所有，但在成果鉴定、报奖、发表论文、出版专著时应注明“××研究工作在新能源汽车智能制造国家地方联合工程研究中心完成”。
              </p>
            </div>
          </div>
          <div class="question-item">
            <div class="question-title">
              Q：提交申请后，如何查看申请进度和结果？
            </div>
            <div class="answer">
              <p>
                开放课题申请在规定的申请时间内集中受理。按照“公平竞争，择优支持”的原则，工程研究中心组织国内外同行专家对申请的课题进行初评，工程研究中心技术委员会根据同行专家初评意见进行会评，确定是否开题，对确定开题课题在工程研究中心网站公示5天。
              </p>
            </div>
          </div>
          <div class="question-item">
            <div class="question-title">Q：提交计划书的时间节点？</div>
            <div class="answer">
              <p>
                资助课题公示结果报工程研究中心领导组批准；申请人在接到立项通知15日内提交开放课题计划任务书，申请人与工程研究中心签订开放课题合同任务书，经所在单位审核后报送工程研究中心存档。
              </p>
            </div>
          </div>
          <div class="question-item">
            <div class="question-title">Q：我的申请为什么被驳回？</div>
            <div class="answer">
              <p>
                工程研究中心综合办公室负责开放课题的申请受理和日常管理工作，有下列情况之一的申请不受理：①《申请书》不符合要求，申报材料不齐全；②不符合资助范围。
              </p>
            </div>
          </div>
        </div>
        <div class="more">
          阅读更多
          <img :src="rightIcon" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      rightIcon: require('@/assets/class/question-right.svg'),
    }
  },
}
</script>
<style lang="less" scoped>
@import "~@/style/global";

.wrapper {
  .g_center_wrapper();
  background: url("../../../../assets/class/question-bg.png");
  height: 1358px;
}

.container {
  .g_center();
  height: 100%;
  margin: 62px auto 75px auto;
  overflow: hidden;
}

.title {
  margin-top: 100px;
  margin-bottom: 50px;
  font-family: PingFang SC;
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 45px;
  text-align: center;
  color: #000000;
}

.description {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;
  text-align: center;
  text-indent: 2em;
  text-transform: uppercase;
  color: #3e3e3e;
}

.imgs {
  display: flex;
  width: 1080px;
  margin: 97px auto 0 auto;
  justify-content: space-around;
  align-content: center;
  .img_wrapper {
    position: relative;
    height: 51px;
    .img {
      .absolutely_center();
    }
  }
}
.question-wrapper {
  background: #fff;
  width: 1400;
  margin: 0 auto;
}
.question-list {
  padding: 50px 25px 0px 50px;
  width: 1325px;
}
.question-item {
  margin-bottom: 25px;
  border-bottom: 1px solid #d9d9d9;
  .question-title {
    font-size: 24px;
    font-weight: 600;
    line-height: 34px;
    text-align: left;
    margin-top: 40px;
  }
  .answer {
    width: 1250px;
    font-size: 20px;
    font-weight: 300;
    line-height: 28px;
    padding-bottom: 20px;
    width: 1250px;
    p {
      margin: 20px 0;
      font-family: PingFang SC;
      font-size: 20px;
      font-weight: 300;
      line-height: 28px;
      letter-spacing: 0.05em;
      text-align: left;
    }
  }
}
.more {
  cursor: pointer;
  padding: 40px 0px 80px 50px;
  font-family: PingFang SC;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  color: #0057ff;
  display: flex;
  align-items: center;
  img {
    margin-left: 10px;
  }
}
</style>
