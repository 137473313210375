<template>
  <div class="wrapper">
    <div class="container">
      <p class="title">课题管理</p>
      <div class="process-wrapper">
        <div class="process-item">
          <img class="process-img" :src="imgs[0]" alt="" />
          <p class="process-desc">签订任务承诺书</p>
        </div>
        <img class="process-line" :src="lineSrc" alt="" />
        <div class="process-item">
          <img class="process-img" :src="imgs[1]" alt="" />
          <p class="process-desc">节点检查</p>
        </div>

        <img class="process-line" :src="lineSrc" alt="" />

        <div class="process-item">
          <img class="process-img" :src="imgs[2]" alt="" />
          <p class="process-desc">验收归档</p>
        </div>
      </div>
    </div>
    <div class="manage-wrapper">
    <div class="manage-list">
        <div class="manage-item">
        <img :src="imgs[3]" alt="" />
        <div class="content-box">
          <p class="content-title">进度汇报</p>
          <p class="content-detail">
            课题负责人每年
            <span class="date">7月30日</span>需到工程中心汇报年度中期进展，
            <span class="date">12月31日</span
            >前须向工程研究中心提交开放课题进度报告、年度内成果证明以及下一年度工作计划
          </p>
        </div>
      </div>
      <div class="manage-item">
        <div class="content-box">
          <p class="content-title">课题跟踪</p>
          <p class="content-detail">
         课题负责人<span class="date">1年以上</span>不参加所申请课题研究或未按要求上报项目执行和进展情况，无故不接受课题实施情况检查、监督的，将暂停项目。

          </p>
        </div>
        <img :src="imgs[4]" alt="" />

      </div>
      <div class="manage-item">
        <img :src="imgs[5]" alt="" />
        <div class="content-box">
          <p class="content-title">课题验收</p>
          <p class="content-detail">
          课题研究完成后，课题负责人需填写《工程研究中心开放课题研究工作总结报告》，并于研究工作结束<span class="date">2个月内</span>报工程研究中心，并提供相应的原始成果证明材料，验收通过后将结题相关材料报工程研究中心。
          </p>
        </div>
      </div>
    </div>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      imgs: [
        require('@/assets/class/manage1.svg'),
        require('@/assets/class/manage2.svg'),
        require('@/assets/class/manage3.svg'),
        require('@/assets/class/manage-img1.png'),
        require('@/assets/class/manage-img3.png'),
        require('@/assets/class/manage-img2.png'),
      ],
      lineSrc: require('@/assets/class/line.png'),
      applyProcess: require('@/assets/class/process-apply.svg'),
    }
  },
}
</script>
<style lang="less" scoped>
@import "~@/style/global";

.wrapper {
  .g_center_wrapper();
}

.container {
  .g_center();
  margin: 62px auto 0px auto;
  overflow: hidden;
}

.title {
  margin: 30px 0 100px 0px;
  font-family: PingFang SC;
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 45px;
  text-align: center;
  color: #000000;
}

.description {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;
  text-align: center;
  text-indent: 2em;
  text-transform: uppercase;
  color: #3e3e3e;

}

.process-wrapper {
  display: flex;
  width: 1084px;
  margin: 0 auto;
  margin-bottom: 60px;
  justify-content: space-between;
}
.process-item {
  width: 128px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.process-final {
  width: 167px;
  p {
    width: 167px;
  }
}
.process-img {
  width: 128px;
  height: 128px;
}
.process-line {
  width: 240px;
  height: 12px;
  margin-top: 50px;
}
.process-desc {
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  width: 146px;
    text-align: center;
}
.manage-wrapper {
  width: 1682px;
  height: 910px;
  background: #f5f5f5;
  margin:0 auto;

}
.manage-list{
 display:flex;
  flex-direction: column;
  margin:75px;
 padding-top:75px;
}
.manage-item{
  display:flex;
  align-items:center;
  img{
    width:470px;
    height:248px;
    margin-right:20px;
  }
  .content-title{
    height:32px;
    line-height:32px;
    border-left:6px solid #0057FF;
    font-size:24px;
    text-indent:10px;
    margin-bottom:20px;
    font-weight:500;

  }
  .content-detail{
    font-size:20px;
    line-height:28px;
    padding-left:20px;
    font-weight:300;
.date{
  color:#0057FF;
}
  }
}
</style>
