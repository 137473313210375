<template>
  <div class="wrapper">
    <div class="container">
      <p class="title">开放对象</p>

      <p class="description">国内外高等院校、科研院所等单位的在职人员</p>
      <img class="building" :src="buildingSrc" />

    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      buildingSrc: require('@/assets/class/building.png'),
    }
  },
}
</script>
<style lang='less' scoped>
@import '~@/style/global';

.wrapper {
  .g_center_wrapper();
  height:500px;
  background:#F5F5F5;
}
.container{
  display:flex;
  flex-direction: column;
  align-items:center;
  .building{
    width:775px;
    height:213px;
  }
}
.title {
  margin: 80px 0  58px 0;
  font-family: PingFang SC;
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 45px;
  text-align: center;
  color: #000000;
}

.description {
font-size: 20px;
font-weight: 400;
line-height: 28px;

margin-bottom:75px;

}

.imgs {
  display: flex;
  width: 1080px;
  margin: 97px auto 0 auto;
  justify-content: space-around;
  align-content: center;
  .img_wrapper {
    position: relative;
    height: 51px;
    .img {
      .absolutely_center();
    }
  }
}
</style>
