<template>
  <div class="wrapper">
    <div class="container">
      <p class="title">申请流程</p>
      <div class="process-wrapper">
        <div class="process-item">
          <img class="process-img" :src="imgs[0]" alt="" />
          <p class="process-desc">提交申请</p>
        </div>
        <img class="process-line" :src="lineSrc" alt="" />
        <div class="process-item">
          <img class="process-img" :src="imgs[1]" alt="" />
          <p class="process-desc">资质评审</p>
        </div>

        <img class="process-line" :src="lineSrc" alt="" />

        <div class="process-item">
          <img class="process-img" :src="imgs[2]" alt="" />
          <p class="process-desc">综合评审</p>
        </div>

        <img class="process-line" :src="lineSrc" alt="" />
        <div class="process-item process-final">
          <img class="process-img" :src="imgs[3]" alt="" />
          <p class="process-desc">确定开题网站公示</p>
        </div>
      </div>
      <div class="apply-btn">
        <div class="btn" @click="$router.push('/classes/apply')">
            <img :src='applyProcess' />
            申请课题</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      imgs: [
        require('@/assets/class/process1.svg'),
        require('@/assets/class/process2.svg'),
        require('@/assets/class/process3.svg'),
        require('@/assets/class/process4.svg'),
      ],
      lineSrc: require('@/assets/class/line.png'),
      applyProcess: require('@/assets/class/process-apply.svg'),
    }
  },
  methods: {

  },
}
</script>
<style lang="less" scoped>
@import "~@/style/global";

.wrapper {
  .g_center_wrapper();
}

.container {
  .g_center();
  height: 100%;
  margin: 62px auto 75px auto;
  overflow: hidden;
}

.title {
  margin: 30px 0 100px 0px;
  font-family: PingFang SC;
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 45px;
  text-align: center;
  color: #000000;
}

.description {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;
  text-align: center;
  text-indent: 2em;
  text-transform: uppercase;
  color: #3e3e3e;
}

.imgs {
  display: flex;
  width: 1080px;
  margin: 97px auto 0 auto;
  justify-content: space-around;
  align-content: center;
  .img_wrapper {
    position: relative;
    height: 51px;
    .img {
      .absolutely_center();
    }
  }
}
.process-wrapper {
  display: flex;
  width: 1084px;
  margin: 0 auto;
  margin-bottom: 100px;
  justify-content: space-between;
}
.process-item {
  width: 128px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.process-final {
  width: 167px;
  p {
    width: 167px;
  }
}
.process-img {
  width: 128px;
  height: 128px;
}
.process-line {
  width: 240px;
  height: 12px;
  margin-top: 50px;
}
.process-desc {
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
}
.apply-btn{
        display: flex;
    justify-content: center;
    margin-bottom: 100px;
    .btn{
        width:226px;
        height:50px;
        text-align:center;
        line-height:50px;
        background:#000;
        color:#fff;
        font-size:16px;
        cursor:pointer;
        display:flex;
        align-items:center;
        justify-content:center;
    }
}
</style>
