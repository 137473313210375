<template>
  <div class="wrapper">
    <div class="container">
      <p class="title">课题类型</p>
      <div class="type-wrapper">
        <div class="type-item">
          <div class="type-container">
            <img :src="typeSrc" />
            <p>一般课题</p>
          </div>
          <p class="description">
            <span class="label"> 研究期限: </span>
           <span class="content"> 1-2年</span>
          </p>
          <p class="detail">
            <span class="label"> 课题要求: </span>
            <span class="content"
              >课题完成预期研究内容，发表课题相关
              SCI收录期刊论文1篇、相关专利1篇</span
            >
          </p>
        </div>
        <div class="type-item">
          <div class="type-container">
            <img :src="typeSrc" />
            <p>重点课题</p>
          </div>
          <p class="description">
            <span class="label"> 研究期限: </span>
           <span class="content"> 3年</span>
          </p>
          <p class="detail">
            <span class="label"> 课题要求: </span>
            <span class="content"
              >课题完成预期研究内容，发表课题相关
              SCI收录期刊论文2篇、相关专利1篇</span
            >
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      typeSrc: require('@/assets/class/class-type.svg'),
    }
  },
}
</script>
<style lang="less" scoped>
@import "~@/style/global";

.wrapper {
  .g_center_wrapper();
  background-color: #064acb;
  color: #fff;
  height: 700px;
  background-image: url("../../../../assets/class/type-background.png");
  background-blend-mode: multiply; /* 叠加模式 */
  background-size: cover;
  background-position: center;
}

.container {
  .g_center();
  height: 100%;
  margin: 62px auto 75px auto;
  overflow: hidden;
}

.title {
  margin-top: 100px;
  color: #fff;
  font-weight: 600;
  font-size: 32px;
  line-height: 45px;
  text-align: center;
}

.imgs {
  display: flex;
  width: 1080px;
  margin: 97px auto 0 auto;
  justify-content: space-around;
  align-content: center;
  .img_wrapper {
    position: relative;
    height: 51px;
    .img {
      .absolutely_center();
    }
  }
}
.type-wrapper {
  display: flex;
  justify-content: center;
  width: 1254px;
  margin: 50px auto;
  .type-item {
    text-align: center;
    width: 627px;
    font-size: 20px;
    .type-container {
      width:467px;
      margin: 0 auto;
      border-radius: 14px;
      display: flex;
      flex-direction: column;
      align-items: center;
      backdrop-filter: blur(7.1px);

      background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0.26) 0%,
        rgba(255, 255, 255, 0.15) 100%
      );

      width: 214px;
      height: 250px;
      font-size: 24px;
      img {
        margin-top: 50px;
      }
      p {
        width: 100%;
        margin: 14px auto;
        line-height: 34px;
      }
    }
    p {
      width: 467px;
      margin: 0 auto;
    }
    .description {
      margin: 50px auto;
    }
    .detail{
      display:flex;
      .label{
       width: 190px;
      }
    }
    .label {
      font-family: PingFang SC;
      font-size: 20px;
      font-weight: 400;
      line-height: 28px;
      letter-spacing: 0.05em;
      text-align: left;
      color: rgba(255, 255, 255, 0.5);
    }
    .content {
      font-family: PingFang SC;
      font-size: 20px;
      font-weight: 300;
      line-height: 28px;
      letter-spacing: 0.05em;
      text-align: left;
    }
  }
  .type-item:nth-child(1) {
    border-right: 1px solid rgba(255, 255, 255, 0.26);
  }
}
</style>
