<template>
  <div class="wrapper">
    <div class="container">
      <div class="top">
        <div class="left">
          <div class="title">联系我们</div>
          <div class="content">邮箱：service@c2m-mom.com</div>
        </div>
        <div class="right">
          <div class="title">快速入口</div>
          <div class="content-list">
            <a v-for="item in list" :key="item.name">{{ item.name }}</a>
          </div>
        </div>
      </div>
      <div class="copyright">copyright©2021 西图盟有限公司版权所有</div>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      list: [
        {
          name: '产品',
          url: '#',
        },
        {
          name: '新闻资讯',
          url: '#',
        },
        {
          name: '关于我们',
          url: '#',
        },
      ],
    }
  },
}
</script>
<style lang="less" scoped>
@import "~@/style/global";

.wrapper {
  .g_center_wrapper();
  background: #2d3845;
  height: 445px;
  color: #fff;
}

.container {
  .g_center();

  width: 1400px;
  margin: 0 auto;
}
.top {
  height: 400px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  display: flex;
  padding-top: 100px;
  box-sizing: border-box;
}
.left,
.right {
  width: 50%;
  .title {
    font-size: 24px;
    font-weight: 600;
    line-height: 34px;
    text-align: left;
    margin-bottom: 30px;
  }
  .content,
  .content-list {
    font-size: 20px;
    font-weight: 400;
    line-height: 28px;
    text-align: left;
    a {
      font-size: 20px;
      font-weight: 400;
      line-height: 28px;
      color: #fff;
      margin-right: 30px;
    }
  }
}
.copyright {
  text-align: center;
  line-height: 30px;
}
</style>
